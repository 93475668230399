import { type ChangeEvent, memo, useCallback, useState } from 'react';
import { motion } from 'framer-motion';

import { Button, Spinner, TextInput, RadioButton, PhoneInput } from '@components/common';
import Logo from '@assets/logo-gray.svg';
import ArrowRight from '@assets/arrow-right.svg?react';
import SuccessIcon from '@assets/success-icon.png';
import { useHandleSubmitContactForm } from '@hooks/index.tsx';
import styles from './QuizForm.module.scss';
import type { CountryData } from 'react-phone-input-2';
import { openContactForm } from '@components/ContactModal';

export type Answer = { id: string; text: string };

export type Question = { id: number; question: string; answers?: Answer[] };

export type Props = { questions: Array<Question>; convertActionTitle: string };

const phoneInputProps = { required: true };
const redirectUrl = '/danke/';

const QuizForm = ({ questions, convertActionTitle }: Props) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [answers, setAnswers] = useState<Record<string, any>>({});
    const [isCompleted, setIsCompleted] = useState(false);

    const currentQuestion = questions[currentIndex];
    const progressArr = new Array(questions.length).fill('');

    const onGoBack = () => {
        setCurrentIndex(currentState => (currentState <= 0 ? currentState : currentState - 1));
    };

    const onGoNext = () => {
        setCurrentIndex(currentState => {
            // open contact modal if currentState is 1
            if (currentState === 1) {
                openContactForm({
                    title: 'Kostenlose Beratung',
                    description: 'Wir melden uns bei dir bezüglicher einer kostenlosen Beratung.',
                    convertActionTitle: 'Nachricht schicken',
                });
                return currentState;
            }
            return currentState + 1 >= questions.length ? currentState : currentState + 1;
        });
    };

    const onEmailChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            setAnswers(currentState => ({
                ...currentState,
                [currentQuestion.id]: {
                    ...currentState?.[currentQuestion.id],
                    email: e.target.value,
                },
            }));
        },
        [currentQuestion],
    );

    const onNameChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            setAnswers(currentState => ({
                ...currentState,
                [currentQuestion.id]: {
                    ...currentState?.[currentQuestion.id],
                    name: e.target.value,
                },
            }));
        },
        [currentQuestion],
    );

    const onPhoneChange = useCallback(
        (value: string, data: CountryData) => {
            setAnswers(currentState => ({
                ...currentState,
                [currentQuestion.id]: {
                    ...currentState?.[currentQuestion.id],
                    phone: value,
                    country: data.name,
                },
            }));
        },
        [currentQuestion],
    );

    const onSuccess = useCallback(() => {
        // setIsCompleted(true);
        window.location.href = `${redirectUrl}?name=${answers[currentQuestion.id].name}&email=${
            answers[currentQuestion.id].email
        }&phone=${answers[currentQuestion.id].phone}`;
    }, [answers, currentQuestion]);

    const {
        isLoading,
        hasError,
        onSubmit: onSubmitForm,
    } = useHandleSubmitContactForm({ form: answers, onSuccess });

    const onSubmit = useCallback(async () => {
        await onSubmitForm();
    }, [onSubmitForm]);

    const isNextDisabled = currentIndex + 1 >= questions.length || !answers[currentQuestion.id];
    const isSubmitDisabled =
        !answers[currentQuestion.id]?.email || !answers[currentQuestion?.id]?.phone;

    const onSelectAnswer = useCallback(
        (item: Answer) => {
            setTimeout(() => {
                onGoNext();
            }, 500);

            setAnswers(currentState => ({
                ...currentState,
                [currentQuestion.id]: item.id,
            }));
        },
        [currentQuestion, isNextDisabled, onGoNext],
    );

    if (isCompleted) {
        return (
            <div className={styles.quizForm}>
                <div className={styles.quizFormHeader} style={{ marginBottom: 0 }}>
                    <img src={Logo.src} alt="butlerapp-logo" />
                </div>
                <QuizCompletedView />
            </div>
        );
    }

    return (
        <div className={styles.quizFormContainer}>
            <div className={styles.quizForm}>
                <div className={styles.quizFormHeader}>
                    {currentIndex !== 0 && (
                        <div className={styles.progressBar}>
                            {progressArr.map((_, i) => (
                                <div
                                    key={i}
                                    className={
                                        styles.progressItem +
                                        (currentIndex > i ? ' ' + styles.progressItemActive : '')
                                    }></div>
                            ))}
                        </div>
                    )}

                    <img src={Logo.src} alt="butlerapp-logo" />
                    <motion.strong
                        key={currentIndex}
                        variants={carouselVariants}
                        initial="enter"
                        animate="center"
                        exit="exit"
                        transition={carouselTransition}>
                        {currentQuestion.question}
                    </motion.strong>
                </div>
                <motion.div
                    className={
                        currentQuestion.answers ? styles.quizFormAnswers : styles.customerInfoForm
                    }
                    key={currentIndex}
                    variants={carouselVariants}
                    initial="enter"
                    animate="center"
                    exit="exit"
                    transition={carouselTransition}>
                    {currentQuestion.answers ? (
                        currentQuestion.answers.map(item => (
                            <QuizAnswerItem
                                key={item.id}
                                {...item}
                                isSelected={answers[currentQuestion.id] === item.id}
                                onSelect={onSelectAnswer}
                            />
                        ))
                    ) : (
                        <>
                            <TextInput
                                placeholder="Name"
                                value={answers[currentQuestion.id]?.name || ''}
                                onChange={onNameChange}
                            />
                            <TextInput
                                required
                                placeholder="E-Mail"
                                type="email"
                                value={answers[currentQuestion.id]?.email || ''}
                                onChange={onEmailChange}
                            />
                            <PhoneInput
                                name="phone"
                                containerClass={styles.phoneInputWrapper}
                                placeholder="Telefon"
                                value={answers[currentQuestion.id]?.phone || ''}
                                onChange={onPhoneChange}
                                inputProps={phoneInputProps}
                                enableSearch
                            />

                            {hasError && (
                                <span className={styles.quizFormErrorText}>
                                    Something went wrong. Please try again.
                                </span>
                            )}
                        </>
                    )}
                </motion.div>

                {currentIndex + 1 !== questions.length ? (
                    currentIndex !== 0 && (
                        <div className={styles.buttonsContainer}>
                            <Button
                                variant="outlined"
                                onClick={onGoBack}
                                disabled={currentIndex <= 0}>
                                Zurück
                            </Button>
                            <Button variant="gradient" onClick={onGoNext} disabled={isNextDisabled}>
                                Weiter
                            </Button>
                        </div>
                    )
                ) : (
                    <Button
                        className={styles.submitButton}
                        variant="gradient"
                        onClick={onSubmit}
                        disabled={isSubmitDisabled || isLoading}>
                        {isLoading && <Spinner />} {convertActionTitle}
                        <ArrowRight />
                    </Button>
                )}
            </div>
        </div>
    );
};

const QuizAnswerItem = ({
    isSelected,
    id,
    text,
    onSelect,
}: Answer & { isSelected: boolean; onSelect: (answer: Answer) => void }) => {
    const onClick = () => {
        onSelect({ id, text });
    };

    return (
        <button
            className={
                styles.quizFormAnswerItem +
                (isSelected ? ' ' + styles.quizFormAnswerItemSelected : '')
            }
            onClick={onClick}>
            <RadioButton checked={isSelected} aria-label="Select answer" onChange={onClick} />
            {text}
        </button>
    );
};

const QuizCompletedView = () => {
    return (
        <div className={styles.quizCompletedView}>
            <strong className={styles.quizCompletedViewTitle}>Anfrage erhalten</strong>
            <div className={styles.quizCompletedViewSuccessIcon}>
                <img src={SuccessIcon.src} alt="success-icon" />
            </div>
            <strong className={styles.quizCompletedMainTitle}>Vielen Dank!</strong>
            <p>
                Wir setzen uns in Kürze mit Ihnen in Verbindung, um all Ihre Fragen zu beantworten.
                Anschließend erhalten Sie eine E-Mail mit den Logindaten für Ihren Demo Zugang.
            </p>
        </div>
    );
};

export const carouselVariants = {
    // direction comes from "custom" attribute
    enter: (direction = 1) => ({
        // x: direction > 0 ? 500 : -500,
        opacity: 0,
    }),
    center: {
        zIndex: 1,
        // x: 0,
        opacity: 1,
    },
    exit: (direction = 1) => ({
        zIndex: 0,
        // x: direction < 0 ? 500 : -500,
        opacity: 0,
    }),
};

export const carouselTransition = {
    x: { type: 'spring', stiffness: 300, damping: 30 },
    opacity: { duration: 0.2 },
};

export default memo(QuizForm);
